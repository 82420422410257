<template>
	<div class="main-wrapper mt-4">
		<CRow class="mb-4">
			<CCol md="9">
				<h4 class="search-weight-title">
					Clear search result cache
				</h4>
				<div class="description typo-body-2 color-black-45">
					<template>
						Users will then see up-to-date search result queried from the search engine
					</template>
				</div>
			</CCol>
			<CCol md="3" class="text-right">
				<button
					class="btn btn-secondary"
					color="secondary"
					@click="$refs['modal-clear-cache'].open()"
				>
					Clear
				</button>
			</CCol>
		</CRow>

		<BaseModalConfirm
			ref="modal-clear-cache"
			title="Clear search result cache?"
			description="This action will affect search usage. Future searches will be queried through search engine once."
			primary-button-text="Clear"
			@onConfirm="clearCache"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';

export default {
	name: 'SearchResultCache',
	components: {
		BaseModalConfirm,
	},
	methods: {
		...mapActions({
			clearCache: 'searchResultCaches/clearCacheSearchResult',
		}),
	},
};
</script>
<style lang="scss" scoped>
	.search-weight-title {
		margin-bottom: rem(4);
	}
</style>
